import React from 'react';
import dataMandant from '../../data/partenaires/dataMandant';
import Select from 'react-select';
import setMarque from '../../data/setMarque';

const optionsMandant = dataMandant.map((item) => ({
  value: `${item[1]}`,
  label: `${item[0]}`,
}));

const ItemMandant = (props) => {
  const color1 = setMarque['laforet-simulateur.hellio.com'].color1;
  return (
    <div className="w-100">
      <div className="input-container flex-input" style={{ maxWidth: '400px' }}>
        <div style={{ width: '50px' }}></div>
        <h3 className="text-main mt-50 mb-20">Status du mandant :</h3>
      </div>

      <div className="input-container flex-input" style={{ maxWidth: '400px' }}>
        <div style={{ width: '50px' }}></div>
        <div style={{ minWidth: '300px' }}>
          <Select
            value={props.mandant}
            onChange={props.handleMandant}
            options={optionsMandant}
            placeholder={<div className="select-placeholder">Sélectionner</div>}
            components={{
              IndicatorSeparator: () => null,
            }}
            noOptionsMessage={() => 'aucune option trouvée'}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#eee',
                primary: color1,
                primary50: '#eee',
                currentColor: 'red',
              },
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: 0,
                boxShadow: 'none',
                color: '#3A3A3A',
              }),
              dropdownIndicator: (provided, state) => ({
                ...provided,
                transform: state.selectProps.menuIsOpen
                  ? 'rotate(0deg)'
                  : 'rotate(270deg)',
                color: color1,
              }),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemMandant;
