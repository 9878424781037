const dataMandant = [
  ['Propriétaire occupant', 'Propriétaire occupant'],
  ['Propriétaire bailleur', 'Propriétaire bailleur'],
  ['Indivisaire occupant', 'Indivisaire occupant'],
  ['Indivisaire autre', 'Indivisaire autre'],
  ['Nu propriétaire', 'Nu propriétaire'],
  ['Usufruitier occupant', 'Usufruitier occupant'],
  ['Usufruitier locataire', 'Usufruitier locataire'],
  ['Je ne sais pas', 'Je ne sais pas'],
];
export default dataMandant;
