/* eslint-disable react/prop-types */
import React from 'react';
import dataPhotovoltaique from 'data/dataPhotovoltaique';
import Amount from 'components/Amount';
import SuccessPage from 'components/success/SuccessPage';
import SuccessParagraph from 'components/success/SuccessParagraph';
import SolaireCard from 'components/success/SolaireCard';
import ToggleSwitch from 'components/success/ToggleSwitch';
class SuccessPhotovoltaiqueSolaireMax extends React.Component {
  render() {
    const offre = this.props.result.data.Offre;
    const panneaux = dataPhotovoltaique[offre]?.panneaux;
    // const packname = dataPhotovoltaique[offre]?.packName;
    const pack = dataPhotovoltaique[offre]?.pack;
    const price =
      this.props.result.data['Information PV SolaireMax'][offre]?.[
        'coût_unitaire'
      ];
    const monthly =
      this.props.result.data['Information PV SolaireMax'][offre]?.[
        'coût_mensuel'
      ];
    const montantPromo = this.props.resultCodePromo.data.montant_promo;
    // const codePromoReste = this.props.resultCodePromo.data.reste_a_charge;
    const resteAPayer = parseFloat(price) - (parseFloat(montantPromo) || 0);
    const PV = this.props.result.data['Information PV SolaireMax'];
    const offreList = [
      'photovoltaïque 3kwc',
      'photovoltaïque 4.5kwc',
      'photovoltaïque 6kwc',
      'photovoltaïque 7.5kwc',
      'photovoltaïque 9kwc',
    ];
    const listSolaireMax = [
      'Système de batterie virtuelle**',
      'Panneaux garantis 25 ans',
      'Installateur local certifié RGE',
      'Application smartphone et ordinateur',
    ];

    return (
      <>
        <SuccessPage
          {...this.props}
          comment={
            <SuccessParagraph>
              *hors abonnements fournisseur et service
              <br />
              ** à partir de 15&nbsp;€&nbsp;/&nbsp;mois
            </SuccessParagraph>
          }
        >
          <ToggleSwitch
            switchON={this.props.switchON}
            onChange={this.props.handleSwitch}
            showSwitch={this.props.showSwitch}
          ></ToggleSwitch>

          <h2 className="solaire-h1">
            SolaireMAX : Ne payez plus d’électricité*, produisez 100% de votre
            consommation
          </h2>
          {/* <p className="p mt-10">Pour tout devis signé avant le 21 juillet Hellio garantit vos économies ou rembourse votre électricité**</p> */}
          <div className="success-item-right mt-20">
            <h5>
              {panneaux} panneaux solaires, <br className="only-show-mobile" />{' '}
              {pack} kWc
            </h5>
            <div>
              <h5
                className="h5-price whitespace-nowrap"
                style={{ fontSize: '20px' }}
              >
                <Amount value={price} />{' '}
                <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
                  ou <Amount value={monthly} />
                  /mois
                </span>
              </h5>
            </div>
          </div>

          {montantPromo && (
            <div className="success-item-right">
              <div>
                <h2
                  className="text-main"
                  style={{ fontSize: '24px', lineHeight: '26px' }}
                >
                  Code Promo
                </h2>
              </div>

              <div>
                <h5 className="h5-prime whitespace-nowrap">
                  - <Amount value={montantPromo} />
                </h5>
              </div>
            </div>
          )}
          <div className="success-item-right last">
            <h5>Reste à payer</h5>
            <div>
              {montantPromo && (
                <h5 className="h5-price whitespace-nowrap">
                  <Amount value={resteAPayer} />
                </h5>
              )}
            </div>
          </div>

          {listSolaireMax.map((item) => (
            <p className="p" key={item}>
              <svg
                width="11"
                height="9"
                viewBox="0 0 11 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.713 8.12L0 4.415L1.412 2.999L3.711 5.294L9.005 0L10.419 1.414L3.713 8.12Z"
                  fill="#20A76A"
                />
              </svg>
              <span style={{ marginLeft: '7px' }}>{item}</span>
            </p>
          ))}
          <p className="p text-main mt-10 font-semibold">
            Hellio s’occupe de toutes vos démarches administratives.
          </p>
        </SuccessPage>
        <div className="row-tablet mt-20 solaire-card-container">
          {offreList.map(
            (item) =>
              offre !== item && (
                <SolaireCard
                  key={item}
                  PV={PV}
                  offre={item}
                  solaireMax={true}
                />
              ),
          )}
        </div>
      </>
    );
  }
}

export default SuccessPhotovoltaiqueSolaireMax;
