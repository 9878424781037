/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from 'data/dataSuccess';
import SuccessPage from 'components/success/SuccessPage';
import CommentBlock from 'components/success/CommentBlock';

class SuccessComment extends React.Component {
  render() {
    return (
      <SuccessPage {...this.props}>
        <CommentBlock>{dataSuccess[this.props.success].h3}</CommentBlock>
      </SuccessPage>
    );
  }
}

export default SuccessComment;
