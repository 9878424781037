import React, { useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from 'images/info.svg';
import { Tooltip } from 'react-tooltip';

export default function InfoTooltip({
  children,
  small = false,
  className = '',
  ...props
}) {
  const idRef = useRef(uniqueId());
  return (
    <>
      <InfoIcon
        data-tooltip-id={idRef.current}
        className={`iconColor1 cursor-pointer align-middle ml-1 ${small ? 'h-3 w-3' : 'h-4 w-4'}`}
        style={{ marginTop: small ? '-0.1em' : 0 }}
      />
      <Tooltip
        place="right"
        clickable
        {...props}
        id={idRef.current}
        className={`tooltip ${className}`}
      >
        {children}
      </Tooltip>
    </>
  );
}
InfoTooltip.propTypes = {
  children: PropTypes.any.isRequired,
  small: PropTypes.bool,
  className: PropTypes.string,
};
