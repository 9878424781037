/* eslint-disable react/prop-types */
import React from 'react';
import SuccessPhotovoltaiqueNormal from '../success/SuccessPhotovoltaiqueNormal';
import SuccessPhotovoltaiqueSolaireMax from '../success/SuccessPhotovoltaiqueSolaireMax';
class SuccessPhotovoltaique extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSwitch: true,
      switchON: false,
    };
  }

  handleSwitch = () => {
    this.setState({ switchON: !this.state.switchON });
  };

  componentDidMount() {
    // month starts at 0, day starts at 1, don't ask me why.
    // desactivated the promo offer after certain date.
    if (new Date() >= new Date(2050, 8, 21)) {
      this.setState({ showSwitch: false, switchON: false });
    }
  }

  render() {
    const props = {
      ...this.props,
      ...this.state,
      handleSwitch: this.handleSwitch.bind(this),
    };
    if (this.state.switchON) {
      return <SuccessPhotovoltaiqueSolaireMax {...props} />;
    } else {
      return <SuccessPhotovoltaiqueNormal {...props} />;
    }
  }
}

export default SuccessPhotovoltaique;
