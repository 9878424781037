/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from 'data/dataSuccess';
import SuccessNormal from 'components/success/SuccessNormal';
import SuccessComment from 'components/success/SuccessComment';
import SuccessBorne from 'components/success/SuccessBorne';
import SuccessAudit from 'components/success/SuccessAudit';
import SuccessPhotovoltaique from 'components/success/SuccessPhotovoltaique';
import SuccessCopro from 'components/success/SuccessCopro';
import SuccessFile from 'components/success/SuccessFile';
import Loader from 'components/Loader';
import ErrorHandler from 'components/ErrorHandler';

class Success extends React.Component {
  renderSuccess(current) {
    const props = {
      ...this.props,
      isPrime:
        this.props?.answers?.Q1 === 'primeHellio' ||
        this.props?.answers?.Q30 === 'primeHellio',
    };
    switch (dataSuccess[current].template) {
      case 'normal':
        return <SuccessNormal {...props} />;
      case 'photovoltaique':
        return <SuccessPhotovoltaique {...props} />;
      case 'borne':
        return <SuccessBorne {...props} />;
      case 'copro':
        return <SuccessCopro {...props} />;
      case 'audit':
        return <SuccessAudit {...props} />;
      case 'comment':
        return <SuccessComment {...props} />;
      case 'file':
        return <SuccessFile {...this.props} />;
      default:
        return <h1>{this.props.success}</h1>;
    }
  }

  componentDidMount() {
    this.props.simulationAPI();
  }

  render() {
    if (this.props.loading) {
      return <Loader></Loader>;
    } else if (this.props.error) {
      console.warn(this.props.error);
      return <ErrorHandler reset={this.props.reset}></ErrorHandler>;
    } else {
      return (
        <div>
          {dataSuccess[this.props.success] && (
            <div>{this.renderSuccess(this.props.success)}</div>
          )}

          {!dataSuccess[this.props.success] && (
            <div className="p-50">
              <h1>{this.props.success}</h1>
            </div>
          )}
        </div>
      );
    }
  }
}

export default Success;
