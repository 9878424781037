/* eslint-disable react/prop-types */
import React from 'react';
import dataPhotovoltaique from 'data/dataPhotovoltaique';
import Amount from 'components/Amount';

class SolaireCard extends React.Component {
  formatPrice = (num) => {
    const str = num.toString();
    if (str.length < 4) {
      return str;
    }
    const arr = Array.from(str);
    for (let i = arr.length - 3; i > 0; i -= 3) {
      arr.splice(i, 0, ' ');
    }
    return arr.join('');
  };

  render() {
    const panneaux = dataPhotovoltaique[this.props.offre].panneaux;
    const packname = dataPhotovoltaique[this.props.offre].packName;
    const pack = dataPhotovoltaique[this.props.offre].pack;
    const monthly = this.props.PV[this.props.offre]['coût_mensuel'];
    const price = this.props.PV[this.props.offre]['coût_unitaire'];
    const prime = this.props.PV[this.props.offre].prime_autoconsommation;
    const resteAPayer = price - prime;

    if (this.props.solaireMax) {
      return (
        <div className="col is-one-quarters">
          <div className="solaire-card">
            <div className="head">
              <h1 className="is-uppercase">{packname}</h1>
              <h2>
                {pack} kWc, {panneaux} panneaux
              </h2>
            </div>
            <div className="content">
              <p>à partir de </p>
              <h1 className="whitespace-nowrap">
                <Amount value={price} />
              </h1>
              <p className="text-main">
                ou <Amount value={monthly} />
                /mois
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col is-one-quarters">
          <div className="solaire-card">
            <div className="head">
              <h1>
                Pack {packname} {pack} kWc
              </h1>
              <h2>{panneaux} panneaux solaires</h2>
            </div>
            <div className="content">
              <h1 className="whitespace-nowrap">
                <Amount value={price} />
              </h1>
              <p className="text-main">
                ou <Amount value={monthly} />
                /mois
              </p>
              <div className="w-100 d-flex aic jcc mv-10">
                <h1 className="prime whitespace-nowrap">
                  - <Amount value={prime} />
                </h1>
              </div>
              <p>Reste à payer</p>
              <h1 className="whitespace-nowrap">
                <Amount value={resteAPayer} />
              </h1>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SolaireCard;
