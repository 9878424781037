import React from 'react';
import Navigation from './Navigation';
// import dataItems from "../data/dataItems";
class Explanation extends React.Component {
  render() {
    const items = this.props.dataItems[this.props.name].list.map((item) => (
      <div className="align-center mb-30 mh-10">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4722_13119)">
            <path
              d="M20.046 3.0477C17.904 1.2357 15.096 0.0957031 12 0.0957031C8.904 0.0957031 6.096 1.2357 3.954 3.0477C1.524 5.2377 0 8.4297 0 11.9517C0 16.3317 2.286 20.0937 5.76 22.2357C7.572 23.2857 9.714 23.9037 12 23.9037C14.286 23.9037 16.428 23.2857 18.24 22.2357C21.714 20.1417 24 16.3317 24 11.9997C24 8.4297 22.476 5.2377 20.046 3.0477ZM10.05 18.2397L4.194 12.4317L5.526 11.0997L10.05 15.5277L18.618 7.1397L19.95 8.4717L10.05 18.2397Z"
              fill="#FBA03D"
            />
          </g>
          <defs>
            <clipPath id="clip0_4722_13119">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <br />
        {item}
      </div>
    ));

    return (
      <div className="fadeIn">
        <div style={{ maxWidth: '720px', margin: '0 auto', fontSize: '14px' }}>
          <p className="mb-30 align-center">
            {this.props.dataItems[this.props.name].content}
          </p>
          <div className="explain-items">{items}</div>
        </div>

        <Navigation
          data={true}
          step={this.props.step}
          nextStep={this.props.nextStep}
          lastStep={this.props.lastStep}
          loadingParcours={this.props.loadingParcours}
        />
      </div>
    );
  }
}

export default Explanation;
