import React from 'react';
import Calendar from 'short-react-calendar';
import dataItems from '../data/dataItems';
import btnX from '../images/btn-x.svg';
class CreneauPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
  }

  render() {
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 2);
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    if (minDate.getDay() == 0 || minDate.getDay() == 6) {
      minDate.setDate(minDate.getDate() + ((1 + 7 - minDate.getDay()) % 7));
    }
    const CreneauItems = dataItems.CreneauPicker.creneau.map((item) => (
      <label className="mr-30" key={item.value}>
        {this.props.creneau == item.value && (
          <input
            type="radio"
            value={item.value}
            name={item.name}
            className="ml-10"
            onChange={this.props.handleCreneau}
            checked
          />
        )}
        {this.props.creneau !== item.value && (
          <input
            type="radio"
            value={item.value}
            name={item.name}
            className="ml-10"
            onChange={this.props.handleCreneau}
          />
        )}
        {item.value}
      </label>
    ));
    return (
      <div className="creneaupicker-bg">
        <div
          className="creneaupicker-bg"
          style={{ zIndex: '0' }}
          onClick={this.props.hidePicker}
        ></div>
        <div className="creneaupicker-box" style={{ zIndex: '99999999999' }}>
          <div
            className="d-flex jcc only-show-mobile aic"
            style={{ justifyContent: 'space-between' }}
            onClick={this.props.hidePicker}
          >
            <div></div>
            <svg
              width="25"
              height="5"
              viewBox="0 0 25 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-30"
            >
              <rect
                x="0.437012"
                y="0.272827"
                width="23.8701"
                height="3.89223"
                rx="1.94611"
                fill="#FBA03D"
              />
            </svg>

            <div>
              <button
                className="btn-x-calendar"
                onClick={this.props.hidePicker}
              >
                <img src={btnX} alt="fermer" />
              </button>
            </div>
          </div>
          <div className="is-hidden-mobile align-right">
            <button className="btn-x-calendar" onClick={this.props.hidePicker}>
              <img src={btnX} alt="fermer" />
            </button>
          </div>

          {/* {minDate.toDateString('fr')}
          {this.props.date.toDateString('fr')} */}
          <Calendar
            onChange={this.props.handleDate}
            value={this.props.date}
            calendarType="ISO 8601"
            oneWeekCalendar={false}
            minDate={minDate}
            maxDate={maxDate}
            locale="fr-Fr"
            minDetail="month"
            tileDisabled={({ activeStartDate, date, view }) =>
              date.getDay() === 0 || date.getDay() === 6
            }
          />

          {/* {this.props.creneau} */}

          <hr className="react-calendar-hr mb-10" />

          <div className="d-flex jcc mb-10 text-main">
            {this.props.date.toLocaleDateString('fr')}
          </div>
          <div className="d-flex jcc text-main">{CreneauItems}</div>
          <div className="d-flex jcc">
            <button
              className="btn-main-hoverlarge is-bold mt-10"
              style={{ cursor: 'pointer' }}
              onClick={this.props.callBtn}
            >
              Être rappelé
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreneauPicker;
