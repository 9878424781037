/* eslint-disable react/prop-types */
import React from 'react';

class ToggleSwitch extends React.Component {
  render() {
    return (
      <div className={this.props.showSwitch ? '' : 'is-hidden'}>
        <div className="d-flex mt-20 mb-10">
          <label className="toggle-switch">
            <input
              type="checkbox"
              onChange={this.props.onChange}
              checked={this.props.switchON}
            />
            <span className="slider"></span>
          </label>
          <div className="ml-10 mt-5">
            <svg
              width="129"
              height="20"
              viewBox="0 0 129 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.1286 17C31.3904 17 30.704 16.904 30.0694 16.7119C29.4477 16.5198 28.8779 16.2318 28.3599 15.8476C27.8548 15.4507 27.4015 14.9641 27 14.388L28.4376 12.7554C29.0722 13.6517 29.6809 14.2727 30.2636 14.6184C30.8464 14.9641 31.5393 15.137 32.3423 15.137C32.8344 15.137 33.2812 15.0602 33.6827 14.9065C34.0842 14.7529 34.4015 14.5416 34.6346 14.2727C34.8677 14.0038 34.9843 13.6965 34.9843 13.3508C34.9843 13.1204 34.9454 12.9027 34.8677 12.6978C34.79 12.493 34.667 12.3073 34.4986 12.1408C34.3432 11.9744 34.136 11.8207 33.877 11.6799C33.6309 11.5391 33.3395 11.4174 33.0028 11.315C32.6661 11.1997 32.2775 11.1037 31.8372 11.0269C31.1378 10.886 30.5291 10.7004 30.0111 10.4699C29.4931 10.2394 29.0592 9.95134 28.7095 9.60563C28.3599 9.25992 28.1008 8.8694 27.9325 8.43406C27.7641 7.98592 27.6799 7.48656 27.6799 6.93598C27.6799 6.39821 27.7965 5.89885 28.0296 5.4379C28.2757 4.97695 28.6059 4.58003 29.0204 4.24712C29.4477 3.90141 29.9463 3.63892 30.5162 3.45967C31.086 3.26761 31.7012 3.17157 32.3617 3.17157C33.0611 3.17157 33.7021 3.2612 34.2849 3.44046C34.8677 3.61972 35.3858 3.8886 35.839 4.24712C36.2923 4.59283 36.6679 5.02177 36.9658 5.53393L35.4894 6.97439C35.2303 6.55186 34.9389 6.19974 34.6152 5.91805C34.2914 5.62356 33.9352 5.40589 33.5467 5.26504C33.1582 5.1114 32.7438 5.03457 32.3034 5.03457C31.7983 5.03457 31.358 5.1114 30.9824 5.26504C30.6068 5.41869 30.309 5.63636 30.0888 5.91805C29.8816 6.18694 29.778 6.50704 29.778 6.87836C29.778 7.14725 29.8298 7.39693 29.9334 7.6274C30.037 7.84507 30.1924 8.04353 30.3996 8.22279C30.6198 8.38924 30.9112 8.54289 31.2738 8.68374C31.6364 8.81178 32.0703 8.92702 32.5754 9.02945C33.2877 9.1831 33.9223 9.38156 34.4792 9.62484C35.0361 9.85531 35.5088 10.1306 35.8973 10.4507C36.2858 10.7708 36.5772 11.1293 36.7715 11.5262C36.9787 11.9232 37.0823 12.3521 37.0823 12.8131C37.0823 13.6709 36.8816 14.4136 36.4801 15.041C36.0786 15.6684 35.5088 16.1549 34.7706 16.5006C34.0324 16.8335 33.1517 17 32.1286 17Z"
                fill="#FBA03D"
              />
              <path
                d="M43.3575 17C42.3603 17 41.4667 16.7759 40.6767 16.3278C39.8996 15.8668 39.2845 15.2458 38.8312 14.4648C38.3779 13.6709 38.1512 12.7682 38.1512 11.7567C38.1512 10.7452 38.3779 9.84891 38.8312 9.06786C39.2845 8.27401 39.8996 7.65301 40.6767 7.20487C41.4667 6.74392 42.3603 6.51344 43.3575 6.51344C44.3418 6.51344 45.2225 6.74392 45.9995 7.20487C46.7895 7.65301 47.4112 8.27401 47.8645 9.06786C48.3178 9.84891 48.5444 10.7452 48.5444 11.7567C48.5444 12.7682 48.3178 13.6709 47.8645 14.4648C47.4112 15.2458 46.7895 15.8668 45.9995 16.3278C45.2225 16.7759 44.3418 17 43.3575 17ZM43.3575 15.1946C43.9662 15.1946 44.5102 15.0474 44.9894 14.7529C45.4685 14.4456 45.8441 14.0359 46.1161 13.5237C46.3881 12.9987 46.5176 12.4097 46.5046 11.7567C46.5176 11.0909 46.3881 10.5019 46.1161 9.98976C45.8441 9.46479 45.4685 9.05506 44.9894 8.76056C44.5102 8.46607 43.9662 8.31882 43.3575 8.31882C42.7488 8.31882 42.1984 8.47247 41.7063 8.77977C41.2271 9.07426 40.8515 9.48399 40.5796 10.009C40.3076 10.5211 40.1781 11.1037 40.191 11.7567C40.1781 12.4097 40.3076 12.9987 40.5796 13.5237C40.8515 14.0359 41.2271 14.4456 41.7063 14.7529C42.1984 15.0474 42.7488 15.1946 43.3575 15.1946Z"
                fill="#FBA03D"
              />
              <path
                d="M49.9865 16.8079V2.59539H51.9874V16.8079H49.9865Z"
                fill="#FBA03D"
              />
              <path
                d="M57.7806 17C56.9388 17 56.1682 16.7695 55.4689 16.3086C54.7825 15.8476 54.2321 15.2202 53.8176 14.4264C53.4032 13.6325 53.196 12.7362 53.196 11.7375C53.196 10.726 53.4032 9.82971 53.8176 9.04866C54.245 8.2548 54.8148 7.6338 55.5272 7.18566C56.2524 6.73752 57.0618 6.51344 57.9555 6.51344C58.4865 6.51344 58.9721 6.59027 59.4124 6.74392C59.8528 6.89757 60.2348 7.11524 60.5586 7.39693C60.8953 7.66581 61.1673 7.97951 61.3745 8.33803C61.5947 8.69654 61.7307 9.08067 61.7825 9.4904L61.3357 9.33675V6.72471H63.356V16.8079H61.3357V14.4072L61.8019 14.2727C61.7242 14.6184 61.5623 14.9577 61.3162 15.2907C61.0831 15.6108 60.7788 15.8988 60.4032 16.1549C60.0406 16.411 59.6326 16.6159 59.1793 16.7695C58.739 16.9232 58.2728 17 57.7806 17ZM58.3051 15.1754C58.9138 15.1754 59.4513 15.0282 59.9175 14.7337C60.3838 14.4392 60.7464 14.0359 61.0054 13.5237C61.2774 12.9987 61.4134 12.4033 61.4134 11.7375C61.4134 11.0845 61.2774 10.5019 61.0054 9.98976C60.7464 9.47759 60.3838 9.07426 59.9175 8.77977C59.4513 8.48528 58.9138 8.33803 58.3051 8.33803C57.7094 8.33803 57.1784 8.48528 56.7122 8.77977C56.2589 9.07426 55.8963 9.47759 55.6243 9.98976C55.3653 10.5019 55.2358 11.0845 55.2358 11.7375C55.2358 12.4033 55.3653 12.9987 55.6243 13.5237C55.8963 14.0359 56.2589 14.4392 56.7122 14.7337C57.1784 15.0282 57.7094 15.1754 58.3051 15.1754Z"
                fill="#FBA03D"
              />
              <path
                d="M65.703 16.8079V6.72471H67.7039V16.8079H65.703ZM66.6743 4.4968C66.2469 4.4968 65.9167 4.38796 65.6836 4.17029C65.4504 3.95262 65.3339 3.64533 65.3339 3.2484C65.3339 2.87708 65.4504 2.57618 65.6836 2.34571C65.9296 2.11524 66.2599 2 66.6743 2C67.1017 2 67.4319 2.10883 67.665 2.3265C67.8982 2.54417 68.0147 2.85147 68.0147 3.2484C68.0147 3.61972 67.8917 3.92061 67.6456 4.15109C67.4125 4.38156 67.0887 4.4968 66.6743 4.4968Z"
                fill="#FBA03D"
              />
              <path
                d="M70.0024 16.8079V6.72471H72.0228V9.89373L71.8285 9.12548C71.971 8.63892 72.2106 8.19718 72.5473 7.80026C72.897 7.40333 73.292 7.08963 73.7323 6.85915C74.1856 6.62868 74.6518 6.51344 75.131 6.51344C75.3512 6.51344 75.5584 6.53265 75.7527 6.57106C75.9599 6.60947 76.1218 6.65429 76.2383 6.70551L75.7138 8.85659C75.5584 8.79257 75.3836 8.74136 75.1893 8.70294C75.008 8.65173 74.8267 8.62612 74.6454 8.62612C74.2957 8.62612 73.959 8.69654 73.6352 8.83739C73.3244 8.96543 73.0459 9.15109 72.7999 9.39437C72.5667 9.62484 72.3789 9.90013 72.2365 10.2202C72.094 10.5275 72.0228 10.8668 72.0228 11.2382V16.8079H70.0024Z"
                fill="#FBA03D"
              />
              <path
                d="M81.3836 17C80.3346 17 79.4021 16.7823 78.5862 16.347C77.7832 15.8988 77.1486 15.2907 76.6824 14.5224C76.2291 13.7542 76.0025 12.8707 76.0025 11.872C76.0025 11.0781 76.132 10.3547 76.391 9.70166C76.65 9.04866 77.0062 8.48527 77.4595 8.01152C77.9257 7.52497 78.4761 7.15365 79.1107 6.89757C79.7583 6.62868 80.4576 6.49424 81.2088 6.49424C81.8693 6.49424 82.4845 6.62228 83.0543 6.87836C83.6241 7.12164 84.1163 7.46095 84.5307 7.89629C84.9581 8.33163 85.2819 8.85019 85.502 9.45198C85.7351 10.041 85.8452 10.6876 85.8323 11.3918L85.8129 12.2369H77.4789L77.0321 10.662H84.0839L83.7925 10.9885V10.5275C83.7537 10.105 83.6112 9.72727 83.3651 9.39437C83.119 9.06146 82.8082 8.79898 82.4327 8.60691C82.0571 8.41485 81.6491 8.31882 81.2088 8.31882C80.5094 8.31882 79.9202 8.45326 79.441 8.72215C78.9618 8.97823 78.5992 9.36236 78.3531 9.87452C78.107 10.3739 77.984 10.9949 77.984 11.7375C77.984 12.4417 78.1329 13.0563 78.4308 13.5813C78.7287 14.0935 79.1496 14.4904 79.6935 14.7721C80.2375 15.0538 80.8656 15.1946 81.5779 15.1946C82.083 15.1946 82.5492 15.1114 82.9766 14.9449C83.4169 14.7785 83.8896 14.4776 84.3947 14.0423L85.4049 15.4443C85.0941 15.7516 84.712 16.0205 84.2587 16.251C83.8184 16.4814 83.3457 16.6671 82.8406 16.8079C82.3485 16.936 81.8628 17 81.3836 17Z"
                fill="#FBA03D"
              />
              <path
                d="M87.4435 16.8079V3.36364H90.8042L95.758 11.3342L93.1354 11.315L98.1474 3.36364H101.392V16.8079H97.6812V13.2164C97.6812 12.064 97.7071 11.0269 97.7589 10.105C97.8237 9.1831 97.9273 8.26761 98.0697 7.35851L98.4971 8.49168L95.0198 13.8118H93.7376L90.338 8.49168L90.7654 7.35851C90.9078 8.21639 91.005 9.09987 91.0568 10.009C91.1215 10.9052 91.1539 11.9744 91.1539 13.2164V16.8079H87.4435Z"
                fill="#FBA03D"
              />
              <path
                d="M101.99 16.8079L107.41 3.36364H110.518L115.899 16.8079H112.072L109.741 10.5659C109.611 10.2202 109.495 9.90013 109.391 9.60563C109.288 9.31114 109.191 9.02305 109.1 8.74136C109.009 8.45967 108.919 8.17157 108.828 7.87708C108.75 7.58259 108.679 7.26248 108.614 6.91677L109.236 6.89757C109.158 7.26889 109.074 7.60179 108.983 7.89629C108.906 8.19078 108.821 8.47247 108.731 8.74136C108.64 9.01024 108.536 9.29193 108.42 9.58643C108.316 9.88092 108.2 10.2074 108.07 10.5659L105.739 16.8079H101.99ZM104.632 14.58L105.778 11.8912H112.072L113.141 14.58H104.632Z"
                fill="#FBA03D"
              />
              <path
                d="M124.357 16.8079L120.142 10.7772L114.741 3.36364H119.384L123.502 9.31754L129 16.8079H124.357ZM114.644 16.8079L119.831 9.4904L123.114 11.0077L119.092 16.8079H114.644ZM123.813 10.5659L120.549 9.10627L124.357 3.36364H128.806L123.813 10.5659Z"
                fill="#FBA03D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 20C16.8954 20 16 19.139 16 18.0769L16 1.92308C16 0.860991 16.8954 3.91405e-08 18 8.74228e-08C19.1046 1.35705e-07 20 0.860991 20 1.92308L20 18.0769C20 19.139 19.1046 20 18 20Z"
                fill="#FBA03D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 19C10.6716 19 10 18.3284 10 17.5L10 2.5C10 1.67157 10.6716 1 11.5 1C12.3284 1 13 1.67157 13 2.5L13 17.5C13 18.3284 12.3284 19 11.5 19Z"
                fill="#FBA03D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 18C5.44772 18 5 17.4883 5 16.8571L5 3.14286C5 2.51167 5.44772 2 6 2C6.55228 2 7 2.51167 7 3.14286L7 16.8571C7 17.4883 6.55228 18 6 18Z"
                fill="#FBA03D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 17C0.447715 17 1.52213e-08 16.6518 3.39978e-08 16.2222L5.77962e-07 3.77778C5.96739e-07 3.34822 0.447716 3 1 3C1.55228 3 2 3.34822 2 3.77778L2 16.2222C2 16.6518 1.55228 17 1 17Z"
                fill="#FBA03D"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default ToggleSwitch;
