/* eslint-disable react/prop-types */
import React from 'react';
import dataPhotovoltaique from 'data/dataPhotovoltaique';
import Amount from 'components/Amount';
import SuccessPage from 'components/success/SuccessPage';
import SuccessParagraph from 'components/success/SuccessParagraph';
import SolaireCard from 'components/success/SolaireCard';
import ToggleSwitch from 'components/success/ToggleSwitch';
class SuccessPhotovoltaiqueNormal extends React.Component {
  render() {
    const offre = this.props.result.data.Offre;
    const panneaux = dataPhotovoltaique[offre]?.panneaux;
    const packname = dataPhotovoltaique[offre]?.packName;
    const pack = dataPhotovoltaique[offre]?.pack;
    const price = this.props.result.data['Coût travaux unitaire (€)'];
    const monthly = this.props.result.data['Coût travaux unitaire mensuel (€)'];
    const prime = this.props.result.data['Montant total prime (€)'];
    const reste = this.props.result.data['Reste à charge unitaire (€)'];
    const montantPromo = this.props.resultCodePromo.data.montant_promo;
    const codePromoReste = this.props.resultCodePromo.data.reste_a_charge;
    const PV = this.props.result.data['Information PV'];
    const offreList = [
      'photovoltaïque 3kwc',
      'photovoltaïque 4.5kwc',
      'photovoltaïque 6kwc',
      'photovoltaïque 7.5kwc',
      'photovoltaïque 9kwc',
    ];

    const paragraphSolaire = (
      <>
        <sup>*</sup>Cette prime est versée automatiquement par EDF Obligation
        d&apos;Achat en même temps que vos revenus générés par la vente de votre
        surplus sur la première année.
      </>
    );

    return (
      <>
        <SuccessPage
          {...this.props}
          comment={<SuccessParagraph>{paragraphSolaire}</SuccessParagraph>}
        >
          <ToggleSwitch
            switchON={this.props.switchON}
            onChange={this.props.handleSwitch}
            showSwitch={this.props.showSwitch}
          />
          <p className="text-s fw-300 mt-10">
            Recommandation&nbsp;:{' '}
            <span className="text-main is-bold">
              Pack {packname} {pack} kWc
            </span>
          </p>
          <div className="success-item-right">
            <h5>Prix pour {panneaux} panneaux solaires</h5>
            <div>
              <h5 className="h5-price whitespace-nowrap">
                <Amount value={price} />
              </h5>
              <h6
                className="text-main align-right whitespace-nowrap"
                style={{ fontSize: '14px' }}
              >
                ou <Amount value={monthly} />
                /mois
              </h6>
            </div>
          </div>
          <div className="success-item-right">
            <div>
              <h2
                className="text-main"
                style={{ fontSize: '24px', lineHeight: '26px' }}
              >
                Prime à l’autoconsommation*
              </h2>
            </div>

            <div>
              <h5 className="h5-prime whitespace-nowrap">
                - <Amount value={prime} />
              </h5>
            </div>
          </div>
          {montantPromo && (
            <div className="success-item-right">
              <div>
                <h2
                  className="text-main"
                  style={{ fontSize: '24px', lineHeight: '26px' }}
                >
                  Code Promo
                </h2>
              </div>

              <div>
                <h5 className="h5-prime whitespace-nowrap">
                  - <Amount value={montantPromo} />
                </h5>
              </div>
            </div>
          )}
          <div className="success-item-right last">
            <h5>Reste à payer</h5>
            <div>
              {!codePromoReste && (
                <h5 className="h5-price whitespace-nowrap">
                  <Amount value={reste} />
                </h5>
              )}
              {codePromoReste && (
                <h5 className="h5-price whitespace-nowrap">
                  <Amount value={codePromoReste} />
                </h5>
              )}
            </div>
          </div>
          <p className="text-s text-main mb-5 font-semibold">
            Hellio s’occupe de toutes vos démarches administratives.
          </p>
        </SuccessPage>
        <div className="row-tablet mt-20 solaire-card-container">
          {offreList.map(
            (item) =>
              offre !== item && <SolaireCard key={item} PV={PV} offre={item} />,
          )}
        </div>
      </>
    );
  }
}

export default SuccessPhotovoltaiqueNormal;
