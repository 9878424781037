/* eslint-disable react/prop-types */
import React from 'react';
import Avis from '../../images/avis-success.svg';
import CreneauPicker from '../CreneauPicker';
class NavSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
    };
  }

  showPicker = (e) => {
    this.setState({ showPicker: true });
  };

  hidePicker = (e) => {
    this.setState({ showPicker: false });
  };

  render() {
    return (
      <div>
        <div className="navSuccess">
          <div className="etre-rappele-box">
            <div>
              <button onClick={this.showPicker} className="creneau-btn">
                Choisir un créneau pour être rappelé
              </button>
            </div>
            <button
              className="btn-main-hoverlarge is-bold"
              style={{ cursor: 'pointer' }}
              onClick={this.props.callBtn}
            >
              Être rappelé
            </button>
            {/* <button className='btn-underline ml-30'>Simuler un prêt pour vos travaux</button>             */}
          </div>
        </div>

        <div className="only-show-mobile" style={{ marginTop: '20px' }}>
          <div className="navigation-mobile-success">
            <div className="d-flex jcsb">
              <button className="btn-retour" onClick={this.props.lastStep}>
                <svg
                  width="35"
                  height="37"
                  viewBox="0 0 35 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="35" height="36.2572" rx="17.5" fill="white" />
                  <path
                    d="M15.9053 12.4395L10.3905 18.176L15.9053 23.8185"
                    stroke="#FBA03D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                  />
                  <path
                    d="M11.5532 18.1758L24.6113 18.1758"
                    stroke="#FBA03D"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                  />
                </svg>
                <span className="gris-hellio">Retour</span>
              </button>
              <img src={Avis} alt="Avis" />
            </div>
          </div>
        </div>
        {this.state.showPicker && (
          <CreneauPicker
            callBtn={this.props.callBtn}
            creneau={this.props.creneau}
            date={this.props.date}
            handleDate={this.props.handleDate}
            handleCreneau={this.props.handleCreneau}
            hidePicker={this.hidePicker}
          />
        )}
      </div>
    );
  }
}

export default NavSuccess;
